export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
    action: "read",
    resource: "dashboard",
  },
  {
    title: "Action List",
    route: "",
    icon: "CalendarIcon",
    action: "read",
    resource: "actionlist",
    children: [
      {
        title: "Welcome Letter",
        route: "",
        resource: "actionlist",
        action: "read",
        children: [
          {
            title: "Client Account",
            route: "welcome-client",
            resource: "actionlist",
            action: "read",
          },
          {
            title: "User",
            route: "welcome-staff",
            resource: "actionlist",
            action: "read",
          },
        ],
      },
      {
        title: "Professional Investor",
        route: { name: "pi" },
        resource: "actionlist",
        action: "read",
      },
      {
        title: "Standing Authority",
        route: { name: "standing-instruction" },
        resource: "actionlist",
        action: "read",
      },
  /*     {
        title: "Discretionary Renewal",
        route: { name: "discretionary-renewal" },
        resource: "actionlist",
        action: "read",
      }, */
      {
        title: "FATCA Certification",
        route: { name: "fatcha-certification" },
        resource: "actionlist",
        action: "read",
      },
      {
        title: "CPT/CPD Compliance",
        route: { name: "course-compliance" },
        resource: "actionlist",
        action: "read",
      },
      {
        title: "SFC Annual Return",
        route: { name: "annual-return" },
        resource: "actionlist",
        action: "read",
      },
      {
        title: "Annual Declaration",
        route: { name: "annual-compliance" },
        resource: "actionlist",
        action: "read",
      },
      {
        title: "Client Birthday",
        route: { name: "client-birthday" },
        resource: "actionlist",
        action: "read",
      },
      {
        title: "Client Passport Expiry",
        route: { name: "client-passport-expiry" },
        resource: "actionlist",
        action: "read",
      },
      {
        title: "User Passport Expiry",
        route: { name: "user-passport-expiry" },
        resource: "actionlist",
        action: "read",
      },
    ],
  },
  // {
  //   title: "Client Account",
  //   route: "clients-list",
  //   icon: "UsersIcon",
  //   action: "read",
  //   resource: "client_account",
  // },
  {
    title: "Client",
    route: "",
    icon: "UsersIcon",
    resource: "client_account",
    action: "read",
    children: [
      {
        title: "Client Account",
        route: "clients-list",
        resource: "client_account",
        action: "read",
      },
      {
        title: "Client Category",
        route: "clientcategory-list",
        resource: "client_account",
        action: "read",
      },
    ],
  },
  {
    title: "User",
    route: "",
    icon: "UsersIcon",
    resource: "user",
    action: "read",
    children: [
      {
        title: "By User",
        route: "users-list",
        resource: "user",
        action: "read",
      },
      {
        title: "By Category",
        route: "teams-list",
        resource: "team",
        action: "read",
      },
    ],
  },
 /*  {
    title: 'Booking',
    route: 'admin-meetings',
    resource: 'Auth',
    action: 'read',
  }, */
  {
    title: "Bank",
    route: "",
    icon: "HomeIcon",
    action: "read",
    resource: "bank",
    children: [
      {
        title: "By Bank",
        route: "bank-list",
        resource: "bank",
        action: "read",
      },
      {
        title: "By RMs",
        route: "bank-detail-list",
        resource: "bank",
        action: "read",
      },
    ],
  },
  {
    title: "Documents",
    route: "",
    icon: "FilePlusIcon",
    resource: "documents",
    action: "read",
    children: [
      {
        title: "User",
        route: "document-staff-list",
        resource: "documents",
        action: "read",
      },
      {
        title: "Client",
        route: "document-client-list",
        resource: "documents",
        action: "read",
      },
    ],
  },

  {
    title: "Permissions",
    route: "",
    icon: "LockIcon",
    resource: "permissions",
    action: "read",
    children: [
      {
        title: "Access Right",
        route: "staff-roles-list",
        resource: "permissions",
        action: "read",
      },
      {
        title: "Role Permission",
        route: "roles-list",
        resource: "permissions",
        action: "read",
      },
    ],
  },
  {
    title: "Training Course",
    route: "users-training-record",
    icon: "BoxIcon",
    action: "read",
    resource: "course",
  },
  {
    title: "Retrocession",
    route: "retrocession-list",
    icon: "BarChartIcon",
    action: "read",
    resource: "retrocession",
  },
  {
    title: "Email",
    route: "email",
    icon: "MailIcon",
    action: "read",
    resource: "email",
  },

  {
    title: "Setting",
    icon: "SettingsIcon",

    children: [
      {
        title: "Document",
        action: "read",
        resource: "setting",
        children: [
          {
            title: "Document Category",
            route: "setting-document-documentcategory",
            action: "read",
            resource: "setting",
          },
          {
            title: "Document Type",
            route: "setting-document-documenttype",
            action: "read",
            resource: "setting",
          },
        ],
      },
      {
        title: "Course",
        action: "read",
        resource: "setting",
        children: [
          {
            title: "Course Setting",
            route: "setting-coursesetting-list",
            action: "read",
            resource: "setting",
          },
          {
            title: "Course Organiser",
            route: "setting-organiser-list",
            action: "read",
            resource: "setting",
          },
        ],
      },
      {
        title: "Account Type",
        route: "setting-account-type-list",
        action: "read",
        resource: "setting",
      },
      {
        title: "Person Type",
        route: "setting-person-type-list",
        action: "read",
        resource: "setting",
      },
      {
        title: "Nationality",
        route: "setting-nationality-list",
        action: "read",
        resource: "setting",
      },
      {
        title: "Email Templates",
        route: "email-template",

        action: "read",
        resource: "setting",
      },
    ],
  },
  {
    title: "Account",
    route: "account-list",
    icon: "UserIcon",
    action: "read",
    resource: "client-user",
  },
];
